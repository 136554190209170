import { useState, useEffect } from "react";
import { Navbar, MobileNav, Button, IconButton } from "@material-tailwind/react";
import LOGO from "../assets/logo.png";
import { Link, NavLink } from "react-router-dom";
import useAuth from "../utils/context/auth-context";
import Avatar from "react-avatar";
import useInfo from "../utils/context/info-context";
import useCart from "../utils/context/cart-context";
import ImagePath from "../utils/other/image_path";
import ImagePathNew from "../utils/other/image_path_new";

function TopNav() {
  const { storageBucketImageRoot } = useInfo();
  const { auth, ...user } = useAuth();
  const [openNav, setOpenNav] = useState(false);
  const { cart } = useCart();
  useEffect(() => {
    window.addEventListener("resize", () => window.innerWidth >= 960 && setOpenNav(false));
  }, []);

  const navList = (
    <div className="container mx-auto pt-4 lg:p-0 lg flex flex-col gap-2 lg:mb-0 lg:flex-row lg:items-center lg:gap-6">
      <NavLink
        end
        onClick={() => setOpenNav(false)}
        to="/"
        className={(_) => "mx-4  group hover:opacity-100 animate text-lg animate hover:text-primary " + (_.isActive ? "text-primary" : " text-secondary")}
      >
        Home
      </NavLink>
      <NavLink
        onClick={() => setOpenNav(false)}
        to="/courses"
        className={(_) => "mx-4  group hover:opacity-100 animate text-lg animate hover:text-primary " + (_.isActive ? "text-primary" : " text-secondary")}
      >
        Courses
      </NavLink>
      <NavLink
        onClick={() => setOpenNav(false)}
        to="/tutors"
        className={(_) => "mx-4  group hover:opacity-100 animate text-lg animate hover:text-primary " + (_.isActive ? "text-primary" : " text-secondary")}
      >
        Tutors
      </NavLink>
      {/* <NavLink
        onClick={() => setOpenNav(false)}
        to="/institutes"
        className={(_) => "mx-4  group hover:opacity-100 animate text-lg animate hover:text-primary " + (_.isActive ? "text-primary" : " text-secondary")}
      >
        Institutes
      </NavLink> */}
      <a
        href="http://support.imsedu.lk"
        target="_blank"
        rel="noreferrer"
        className={`mx-4  group hover:opacity-100 animate text-lg animate hover:text-primary text-secondary`}
      >
        Support
      </a>
      {/* <NavLink
        onClick={() => setOpenNav(false)}
        to="/support"
        className={(_) => "mx-4  group hover:opacity-100 animate text-lg animate hover:text-primary " + (_.isActive ? "text-primary" : " text-secondary")}
      >
        Support
      </NavLink> */}
      {/* <NavLink
        onClick={() => setOpenNav(false)}
        to="/about-us"
        className={(_) => "mx-4  group hover:opacity-100 animate text-lg animate hover:text-primary " + (_.isActive ? "text-primary" : " text-secondary")}
      >
        About Us
      </NavLink> */}
    </div>
  );

  return (
    <div className="fixed w-full z-50">
      <Navbar
        color="transparent"
        shadow={false}
        blurred={false}
        className={`py-2 px-0 lg:py-4 rounded-none bg-light container mx-auto rounded-b-xl ${openNav ? "shadow-lg" : ""}`}
      >
        <div className="flex items-center justify-between px-4 lg:px-8">
          <NavLink to="/" onClick={() => setOpenNav(false)}>
            <img src={LOGO} alt="IMS Institute" className="w-36 md:w-60 h-10 object-scale-down" />
          </NavLink>
          <div className="hidden lg:block flex-grow">{navList}</div>
          <div className="lg:hidden flex-grow"></div>

          {auth ? (
            <NavLink to="/profile" onClick={() => setOpenNav(false)}>
              <div className="items-center justify-end flex">
                <Link to="/cart" className="flex justify-center items-center mr-4">
                  <>
                    <p className="mr-1 ">Cart</p>
                    <p className="bg-gradient text-white rounded-full flex items-center justify-center w-6 h-6 text-sm">{Object.keys(cart).length}</p>
                  </>
                </Link>
                {user.profile?.avatar_url_new ? (
                  <img src={ImagePathNew({path:user.profile?.avatar_url_new})} alt={user.fname} className="mx-auto rounded-full w-10 h-10" />
                ) : (
                  <Avatar name={user.fname + " " + user.lname} color="#000056" size="40" round={true} className="mx-auto" />
                )}
                <h4 className="text-primary invisible lg:visible capitalize ml-2 max-w-xs truncate">
                  {user.fname} <br />
                  <span className="font-light">{user.lname}</span>
                </h4>
              </div>
            </NavLink>
          ) : (
            <div className="flex items-center justify-end">
              {/* <Link to="/cart" className="flex justify-center items-center mr-4">
                <>
                  <p className="mr-2">Cart</p>
                  <p className="bg-gradient text-white rounded-full flex items-center justify-center w-6 h-6 text-sm">{Object.keys(cart).length}</p>
                </>
              </Link> */}
              <div className="grid grid-cols-2 gap-2">
                <NavLink to="/auth/login" onClick={() => setOpenNav(false)}>
                  <Button
                    variant="filled"
                    size="lg"
                    color="orange"
                    ripple={true}
                    className="invisible lg:visible font-medium capitalize text-base rounded-full button-sm bg-gradient"
                  >
                    <p className="py-1 px-2 text-white">Login</p>
                  </Button>
                </NavLink>
                {/* <NavLink to="/auth/register" onClick={() => setOpenNav(false)}>
                  <Button variant="filled" size="sm" color="blue" ripple={true} className="invisible lg:visible font-normal capitalize text-base">
                    <p className="px-2 text-white">Sign Up</p>
                  </Button>
                </NavLink> */}
              </div>
            </div>
          )}

          <IconButton
            variant="text"
            className="ml-2 h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:invisible text-gray-700"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="h-6 w-6" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </IconButton>
        </div>
        <MobileNav open={openNav} className="">
          <div className="p-4">
            {navList}
            {auth ? (
              <>
                {/* <NavLink to="/profile" onClick={() => setOpenNav(false)}>
                  <div className="flex items-center justify-start">
                      {user.profile?.avatar_url ? (
                  <img src={storageBucketImageRoot + user.profile?.avatar_url} alt={user.fname} className="mx-auto rounded-full w-10 h-10" />
                ) : (
                  <Avatar name={user.fname + " " + user.lname} color="#227AFF" size="40" round={true} className="mx-auto" />
                )}
                    <h4 className="text-black capitalize">
                      {user.fname} <span className="font-light">{user.lname}</span>
                    </h4>
                  </div>
                </NavLink> */}
              </>
            ) : (
              <div className="flex items-center justify-center w-full">
                <NavLink to="/auth/login" onClick={() => setOpenNav(false)}>
                  <Button
                    variant="filled"
                    size="lg"
                    color="orange"
                    ripple={true}
                    className="font-medium capitalize text-base rounded-full button-sm bg-gradient"
                  >
                    <p className="py-1 px-2 text-white">Login</p>
                  </Button>
                </NavLink>
                {/* <NavLink to="/auth/register" onClick={() => setOpenNav(false)}>
                  <Button variant="filled" size="sm" color="blue" ripple={true} className="font-normal capitalize text-base">
                    <p className="px-2 text-white">Sign Up</p>
                  </Button>
                </NavLink> */}
              </div>
            )}
          </div>
        </MobileNav>
      </Navbar>
    </div>
  );
}

export default TopNav;
