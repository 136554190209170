import { ToastContainer } from "material-react-toastify";
import React, { useEffect, useState } from "react";
import SuspenseScreen from "./router/suspense-screen";
import Router from "./router";
import useInfo from "./utils/context/info-context";
import useAuth from "./utils/context/auth-context";
import useCart from "./utils/context/cart-context";

function App() {
  const { init } = useInfo();
  // const [authInit, setAuthInit] = useState<boolean>(false);
  const { me ,init:_auth_init} = useAuth();
  const { getCart } = useCart();

  useEffect(() => {
    i();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const i = async () => {
    await me();
    await getCart();
    // setAuthInit(true);
  };

  return (
    <div className="w-full overflow-x-hidden">
      {init && _auth_init ? <Router /> : <SuspenseScreen />}
      <ToastContainer position="top-right" className="mt-32  md:mt-16" />
    </div>
  );
}

export default App;
